<template>
  <label
    v-if="inputStyle === 'primary'"
    class="block s-registration-label mb-2 relative"
  >
    <span
      v-if="theTitle && showTitle"
      class="flex tracking-widest mb-1 form-name items-center"
    >
      <span
        >{{ theTitle
        }}<span
          v-if="required"
          class="text-red-500"
          >*</span
        ></span
      >
    </span>

    <span>
      <slot :has-error="hasError" />
    </span>

    <span class="s-error-message block pt-2 h-auto md:h-3">
      <span>{{ errorMsg }}</span>
    </span>
  </label>

  <div
    v-if="inputStyle === 'float'"
    class="block s-registration-label mb-2 relative"
  >
    <slot :has-error="hasError" />
    <label
      :id="name"
      :for="name"
      class="absolute left-[12px] top-[1rem] text-gray-600 transition-all peer-placeholder-shown:top-[1rem] peer-placeholder-shown:transform peer-focus:-top-0 font-alt"
    >
      <span
        v-if="theTitle && showTitle"
        class="flex tracking-widest form-name items-center"
      >
        <span>
          {{ theTitle
          }}<span
            v-if="required"
            class="text-red-500"
            >*</span
          >
        </span>
      </span>
    </label>
    <span class="s-error-message block pt-2 h-auto md:h-3 font-alt">
      <span>{{ errorMsg }}</span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'ValidatedContainer',
    props: {
      /**
       * The name of this component. Equivalent to name field on input
       */
      name: {
        type: String,
        required: true
      },

      /** The title that will be rendered on the screen. If null title will be determined from name field */
      title: {
        type: String,
        default: null
      },

      showTitle: {
        type: Boolean,
        default: true
      },

      /**
       * A list of validation errors. If a key in this object equals the :name prop of this component then the message
       * in that key will be rendered
       */
      errors: {
        type: Object,
        default() {
          return {}
        }
      },

      tooltip: {
        type: Boolean,
        default: false
      },

      tooltipText: {
        type: String,
        default: ''
      },

      /**
       * If true this field will be disabled
       */
      disabled: {
        type: Boolean,
        default: false
      },
      /**
       * If true this field will be disabled
       */
      required: {
        type: Boolean,
        default: false
      },

      /**
       * Style of the input (Primary / else: Float Placeholder)
       */
      inputStyle: {
        type: String,
        default: 'float'
      }
    },

    data() {
      return {
        showPassword: false
      }
    },

    computed: {
      /** Returns true if the :errors prop contains an error for this component */
      hasError() {
        return !!this?.errorMsg
      },

      /**
       * The validation error for this component. Returns null if all ok
       */
      errorMsg() {
        return this.errors?.[this.name]?.join(',') || null
      },

      /**
       * The title of this input. If no title is provided we attempt to create one using the :name prop. Assumes name
       * is in snake_case. Example:  first_name will become "First Name"
       */
      theTitle() {
        if (this.title !== null) {
          return this.title
        }
        return this.name
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ')
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-registration-label {
    color: #7c93a5;
    @apply text-xs;
  }

  #fulfillment_date .flex.tracking-widest.form-name.items-center {
    display: none;
  }

  .s-registration-input {
    border: solid #e5eaed 2px;
    color: #002d52;
    min-width: 100%;
    @apply text-sm;
  }

  .s-registration-input:focus {
    outline: none;
  }

  .validation-error {
    border-color: #fe84b8;
    background: rgba(243, 182, 208, 0.3);
  }

  .s-error-message {
    color: #fe84b8;
  }

  .s-registration-password-svg {
    color: #e5eaed;
    float: right;
    right: 15px;
    top: 37px;
    cursor: pointer;
  }

  .show-password {
    color: #fe84b8;
  }
</style>
